import React, { useState } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { navigate } from "gatsby";
import "./MainCta.scss";

const MainCta = React.memo(
    ({
        buttonClick,
        mouseXBtnMv,
        mouseYBtnMv,
        mainCtaLabel,
        locale,
        setIsBackgroundColor,
    }) => {
        // this state is triggered by the main cta
        const [isExplorer, setIsExplore] = useState(false);

        const exploreMap = (e) => {
            e.preventDefault();
            buttonClick();
            setTimeout(() => {
                setIsExplore(true);
                navigate(`/${locale}/map`);
            }, 1200);
        };

        const animateMainCta = {
            initial: {
                opacity: 0,
            },

            exit: {
                pointerEvents: "none",
                opacity: 0,
                transition: {
                    ease: "easeOut",
                    type: "tween",
                },
            },

            enter: {
                opacity: 1,
                transition: {
                    delay: 1.5,
                    ease: "easeIn",
                    type: "tween",
                    duration: 0.5,
                },
            },
        };

        const handleHover = () => {
            setIsBackgroundColor("#f02b6c");
        };

        return (
            <motion.div
                initial="initial"
                animate={`${isExplorer ? "exit" : "enter"}`}
                variants={animateMainCta}
                x={mouseXBtnMv}
                y={mouseYBtnMv}
                key="main-cta"
                className="main-cta"
            >
                <a
                    className="button"
                    onClick={exploreMap}
                    href={`/${locale}/map`}
                    onMouseOver={handleHover}
                >
                    <span className={"button__label"}>{mainCtaLabel}</span>
                    <div className="pulse"></div>
                    <div className="pulse-2"></div>
                </a>
            </motion.div>
        );
    }
);

MainCta.propTypes = {
    mainCtaLabel: PropTypes.string.isRequired,
};

export default MainCta;
