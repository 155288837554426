import React from "react";
import "./FullpageLoader.scss";
import { motion, AnimatePresence } from "framer-motion";

const loaderWrapper = {
    visible: {
        opacity: 1,
    },
    hidden: {
        opacity: 0,
        transition: {
            when: "afterChildren",
            duration: 0.5,
        },
    },
};

const loaderImage = {
    hidden: {
        opacity: 0,
        y: -15,
        transition: {
            duration: 0.8,
        },
    },
};

const FullpageLoader = ({ isLoading, isAbsolute }) => {
    return (
        <AnimatePresence>
            {isLoading && (
                <motion.div
                    variants={loaderWrapper}
                    className={
                        !isAbsolute
                            ? "fullpage-loader"
                            : "fullpage-loader fullpage-loader--absolute"
                    }
                    initial="visible"
                    animate="visible"
                    exit="hidden"
                >
                    <motion.img
                        variants={loaderImage}
                        key="/loader_globe.gif"
                        src="/loader_globe.gif"
                        exit="hidden"
                    />
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default FullpageLoader;
