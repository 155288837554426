import React from "react";
import SEO from "components/seo";
import { graphql } from "gatsby";
import FrontpageLayout from "components/layout/FrontpageLayout";
import { Helmet } from "react-helmet";
import config from "../../../config/site-config";
import FontPreload from "components/font-preload";
import facebookShare from "images/shareImages/frontpage/share-facebook.jpg";

const FrontPage = ({ pageContext, data, location }) => {
    const {
        frontpageData,
        nativeLanguages,
        videoGrid,
        globalTexts,
        articlePages,
        serviceMenuLinks,
    } = data;
    const { locale } = pageContext;
    const seo = { lang: locale };

    if (frontpageData && frontpageData.seo) {
        seo.title = frontpageData.seo.title || "";
        seo.description = frontpageData.seo.description || "";
    }

    seo.shareImageUrl = facebookShare;

    return (
        <>
            <SEO lang={locale} {...seo} />
            <Helmet>
                {data?.nativeLanguages?.edges.map((currentSlug, key) => {
                    return (
                        <link
                            key={key}
                            rel="alternate"
                            href={`${config.siteUrl}/${currentSlug.node.code}`}
                            hreflang={
                                currentSlug.node.code === "en"
                                    ? "x-default"
                                    : currentSlug.node.code
                            }
                        />
                    );
                })}
            </Helmet>
            <FontPreload />
            <FrontpageLayout
                pageType="frontpage"
                frontpageData={frontpageData}
                locale={locale}
                nativeLanguages={nativeLanguages}
                videoGrid={videoGrid.edges[0].node}
                globalTexts={globalTexts}
                articlePages={articlePages}
                serviceMenuLinks={serviceMenuLinks}
                location={location}
            />
        </>
    );
};

export default FrontPage;

export const query = graphql`
    query frontpageData($locale: String!) {
        frontpageData: datoCmsFrontpage(locale: { eq: $locale }) {
            id: originalId
            aboutPageDescription
            appUrlAndroid
            appUrlIos
            appUrlLabel
            mainCtaLabel
            logoText
            seo {
                title
                description
                twitterCard
                image {
                    url
                }
            }
            takeActionLink {
                menuTitle
                slug
            }
            articlePageLink {
                menuTitle
                slug
            }
        }
        articlePages: allDatoCmsArticle(
            filter: { title: { ne: null }, locale: { eq: $locale } }
            sort: { fields: position }
        ) {
            edges {
                node {
                    slug
                    menuTitle
                    hideFromNavigation
                }
            }
        }
        serviceMenuLinks: allDatoCmsServiceMenu(
            filter: {
                locale: { eq: $locale }
                internalLink: { slug: { ne: null } }
            }
            sort: { fields: position }
        ) {
            edges {
                node {
                    menuTitle
                    locale
                    externalLink
                    linkType
                    internalLink {
                        slug
                    }
                }
            }
        }
        nativeLanguages: allDatoCmsLanguage(
            filter: { locale: { eq: $locale } }
        ) {
            edges {
                node {
                    nativeName
                    name
                    code
                }
            }
        }
        globalTexts: datoCmsGlobalText(locale: { eq: $locale }) {
            languageSelectorLabel
            mapMenuLabel
            streamingMenuLabel
            toolkitsMenuLabel
        }
        videoGrid: allDatoCmsFrontpageVideoGrid(
            filter: { locale: { eq: $locale } }
        ) {
            edges {
                node {
                    columnFive {
                        cardId
                        categoryColor {
                            color
                        }
                    }
                    columnFour {
                        cardId
                        categoryColor {
                            color
                        }
                    }
                    columnOne {
                        cardId
                        categoryColor {
                            color
                        }
                    }
                    columnSeven {
                        cardId
                        categoryColor {
                            color
                        }
                    }
                    columnSix {
                        cardId
                        categoryColor {
                            color
                        }
                    }
                    columnThree {
                        cardId
                        categoryColor {
                            color
                        }
                    }
                    columnTwo {
                        cardId
                        categoryColor {
                            color
                        }
                    }
                }
            }
        }
    }
`;
