import React from "react";
import PropTypes from "prop-types";
import FrontPageTemplate from "templates/frontpage/FrontpageTemplate";
import CookieConsent from "components/cookie-consent";
// import SmartBanner from "react-smartbanner";
// import "react-smartbanner/dist/main.css";
import "./Layout.scss";

const FrontpageLayout = ({
    pageType,
    frontpageData,
    locale,
    nativeLanguages,
    globalTexts,
    videoGrid,
    articlePages,
    location,
    serviceMenuLinks,
}) => {
    return (
        <>
            <CookieConsent language={locale} />
            <main className={pageType}>
                <FrontPageTemplate
                    locale={locale}
                    nativeLanguages={nativeLanguages}
                    videoGrid={videoGrid}
                    globalTexts={globalTexts}
                    pageType={pageType}
                    articlePages={articlePages}
                    location={location}
                    serviceMenuLinks={serviceMenuLinks}
                    {...frontpageData}
                />
            </main>
        </>
    );
};

FrontpageLayout.propTypes = {
    pageType: PropTypes.string,
};

export default FrontpageLayout;
