import React from "react";
import "./FrontpageGrid.scss";
import { Link } from "gatsby";
import { isMobile } from "react-device-detect";
import { motion } from "framer-motion";
import SoundOnIcon from "images/sound-on.inline.svg";
import { window, exists } from "browser-monads";
import classNames from "classnames";
import getCategoryColor from "constants/categoryColors";

class FrontpageGrid extends React.PureComponent {
    constructor(props) {
        super(props);
        exists(window);

        const { videoGrid } = this.props;

        const {
            columnOne,
            columnTwo,
            columnThree,
            columnFour,
            columnFive,
            columnSix,
            columnSeven,
        } = videoGrid;

        const column1Bis = [...columnOne, ...columnOne];
        const column2Bis = [...columnTwo, ...columnTwo];
        const column3Bis = [...columnThree, ...columnThree];
        const column4Bis = [...columnFour, ...columnFour];
        const column5Bis = [...columnFive, ...columnFive];
        const column6Bis = [...columnSix, ...columnSix];
        const column7Bis = [...columnSeven, ...columnSeven];

        this.columns = [
            column1Bis,
            column2Bis,
            column3Bis,
            column4Bis,
            column5Bis,
            column6Bis,
            column7Bis,
        ];
        this.id = 0;
        this.errorCount = 0;
        this.successCount = 0;

        this.state = {
            isPossibleToListen: false,
            isTransitioning: false,
        };

        this.isSmallDevice = window.innerWidth < 480;

        this.maximumPreloaderWait = 1500;
        this.contentLoaded = false;
    }

    handleHover = (e) => {
        const itemBgColor = e.target.getAttribute("attr-color");
        if (!this.props.isTransitioning) {
            const bgColor = getCategoryColor(itemBgColor);

            this.props.setIsBackgroundColor(bgColor);

            if (this.state.isPossibleToListen) {
                const playPromise = e.target.play();
                if (playPromise !== undefined) {
                    playPromise
                        .then((_) => {
                            // Automatic playback started!
                            // console.warn("audio played auto");
                        })
                        .catch((error) => {
                            // Auto-play was prevented
                            // Show paused UI.
                            console.warn("playback prevented");
                        });
                }
            }
        }
    };

    handlePause = (e) => {
        e.target.pause();
    };

    createSlider = () => {
        const slideClasses = classNames("slide", {
            "slide--mobile": isMobile,
        });

        const slider = this.columns.map((items, key) => {
            this.id++;
            return (
                <motion.div
                    className={`slide-track slide-track--${key} slide-track--is-translating`}
                    key={`${key}-${this.id}`}
                    initial="initial"
                    exit="initial"
                    animate={this.props.fadeOutContent ? "exit" : "enter"}
                    variants={this.props.contentTransition}
                >
                    {items.map((item, keyId) => {
                        return (
                            <motion.div
                                className="slide-container"
                                key={`${keyId}-${item.cardId}`}
                                onContextMenu={(e) => e.preventDefault()}
                            >
                                <Link
                                    className={slideClasses}
                                    state={{
                                        prevPath: this.props.location.pathname,
                                        fromFrontpage: true,
                                    }}
                                    to={`/${this.props.locale}/message?id=${item.cardId}`}
                                >
                                    {!isMobile ? (
                                        <video
                                            loop
                                            autoPlay={false}
                                            className="slide-video"
                                            attr-color={
                                                item?.categoryColor?.color
                                            }
                                        >
                                            <source
                                                src={`https://media.earthspeakr.art/video/upload/h_600,f_auto,q_auto/messages/${item.cardId}.mp4`}
                                            />
                                        </video>
                                    ) : this.isSmallDevice ? (
                                        <img
                                            className="slide-image"
                                            src={`https://media.earthspeakr.art/video/upload/dl_0,f_jpg,q_auto,h_300/messages/${item.cardId}.jpg`}
                                            alt="message card"
                                            loading="lazy"
                                        />
                                    ) : (
                                        <img
                                            className="slide-image"
                                            src={`https://media.earthspeakr.art/video/upload/dl_0,f_jpg,q_auto,h_600/messages/${item.cardId}.jpg`}
                                            alt="message card"
                                            loading="lazy"
                                        />
                                    )}
                                </Link>
                                {!isMobile && (
                                    <div
                                        className="prior-action-sound"
                                        onClick={(e) => {
                                            this.setState({
                                                isPossibleToListen: true,
                                            });
                                        }}
                                    >
                                        <span>
                                            <SoundOnIcon />
                                        </span>
                                    </div>
                                )}
                            </motion.div>
                        );
                    })}
                </motion.div>
            );
        });

        const addMouseEvents = (videoElement) => {
            videoElement.addEventListener("mouseover", this.handleHover);
            videoElement.addEventListener("mouseout", this.handlePause);
        };

        this.setState(
            {
                slider,
            },
            () => {
                const allVideos = document.querySelectorAll(".slide-video");
                Promise.all(
                    [...allVideos].map((video) => {
                        return new Promise((resolve) => {
                            video.addEventListener(
                                "canplaythrough",
                                () => {
                                    addMouseEvents(video);
                                    resolve();
                                },
                                {
                                    once: true,
                                }
                            );
                        });
                    })
                ).then(() => {
                    if (!this.contentLoaded) {
                        this.props.onContentLoaded();
                        this.contentLoaded = true;
                    }
                });

                setTimeout(() => {
                    if (!this.contentLoaded) {
                        this.props.onContentLoaded();
                        this.contentLoaded = true;
                    }
                }, this.maximumPreloaderWait);
            }
        );
    };

    componentDidUpdate(prevProps) {
        if (
            this.props.isTransitioning &&
            prevProps.isTransitioning !== this.props.isTransitioning
        ) {
            this.setState({ isTransitioning: true });
            // transition to map
            this.props.setIsBackgroundColor("#f02b6c");
        }
    }

    componentDidMount() {
        this.createSlider();
    }

    componentWillUnmount() {
        const allVideos = document.querySelectorAll(".slide-video");

        for (let index = 0; index < allVideos.length; index++) {
            const element = allVideos[index];
            element.removeEventListener("mouseover", this.handleHover);
            element.removeEventListener("mouseout", this.handlePause);
        }
    }

    render() {
        const frontpageGridClasses = classNames("frontpage-grid", {
            "frontpage-grid--playable": this.state.isPossibleToListen,
            "frontpage-grid--scaled-down": this.state.isTransitioning,
        });

        return (
            <div
                className={frontpageGridClasses}
                style={{
                    backgroundColor: this.props.isBackgroundColor,
                }}
            >
                {this.state.slider}
            </div>
        );
    }
}

export default FrontpageGrid;
