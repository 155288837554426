import React, { useState } from "react";
import NavigationPillar from "components/navigation-pillar";
import useWindowSize, { breakpoints } from "hooks/useWindowSize";
import MainCTA from "components/main-cta";
import Logo from "components/logo";
import { motion, useSpring, useTransform } from "framer-motion";
import "./FrontpageTemplate.scss";
import LanguageSelector from "components/language-selector";
import FrontpageGrid from "components/frontpage-grid";
import FullpageLoader from "components/fullpage-loader";

const contentTransition = {
    exit: {
        opacity: 0,
        transition: {
            type: "tween",
            ease: "easeOut",
            duration: 0.5,
        },
    },

    initial: {
        opacity: 0,
    },

    enter: {
        opacity: 1,
        transition: {
            type: "tween",
            ease: "easeIn",
            duration: 0.2,
        },
    },
};

const FrontpageTemplate = React.memo(
    ({
        locale,
        location,
        articlePageLink,
        aboutPageDescription,
        mainCtaLabel,
        globalTexts,
        nativeLanguages,
        logoText,
        takeActionLink,
        videoGrid,
        currentSlug,
        pageType,
        articlePages,
        serviceMenuLinks,
    }) => {
        // States
        const [fadeOutContent, setFadeOutContent] = useState(false);
        const [isLoading, setIsLoading] = useState(true);

        const [isBackgroundColor, setIsBackgroundColor] = useState("#f02b6c");

        // LanguageSelector state
        const [isLanguageSelectorActive, setIsLanguageSelectorActive] =
            useState(false);

        // frontpage Grid state
        const [isTransitioning, setIsTransitioning] = useState(false);

        // Window size
        const size = useWindowSize();
        const smallScreen = size.width < breakpoints.md;

        // Mouse and Transform Measurement (framer-motion)
        let mouseXmv = useSpring(0);
        let mouseYmv = useSpring(0);
        let mouseXBtnMv = useTransform(mouseXmv, (v) => v / 30);
        let mouseYBtnMv = useTransform(mouseYmv, (v) => v / 30);

        const mouseMoveMent = (event) => {
            let offsetX = event.clientX - window.innerWidth / 2;
            let offsetY = event.clientY - window.innerHeight / 2;
            mouseXmv.set(offsetX);
            mouseYmv.set(offsetY);
        };

        const handleCardScale = () => {
            setIsTransitioning(true);
        };

        const handleLoadingDone = () => {
            document.body.classList.add("loaded");
            setIsLoading(false);
        };

        return (
            <>
                <FullpageLoader isLoading={isLoading} />
                <motion.div
                    className={
                        isTransitioning
                            ? "frontpage-template frontpage-template--is-transitioning"
                            : "frontpage-template"
                    }
                    onMouseMove={mouseMoveMent}
                    initial="initial"
                    exit="exit"
                    animate={fadeOutContent ? "exit" : "enter"}
                    variants={contentTransition}
                >
                    <FrontpageGrid
                        videoGrid={videoGrid}
                        isTransitioning={isTransitioning}
                        locale={locale}
                        location={location}
                        setIsBackgroundColor={setIsBackgroundColor}
                        isBackgroundColor={isBackgroundColor}
                        contentTransition={contentTransition}
                        setFadeOutContent={setFadeOutContent}
                        fadeOutContent={fadeOutContent}
                        onContentLoaded={handleLoadingDone}
                    />
                    <Logo
                        logoLight={true}
                        locale={locale}
                        logoText={logoText}
                    />
                    <MainCTA
                        mainCtaLabel={mainCtaLabel}
                        buttonClick={handleCardScale}
                        mouseXBtnMv={mouseXBtnMv}
                        mouseYBtnMv={mouseYBtnMv}
                        locale={locale}
                        setIsBackgroundColor={setIsBackgroundColor}
                    />
                </motion.div>
                <NavigationPillar
                    setFadeOutContent={setFadeOutContent}
                    text={aboutPageDescription}
                    label={articlePageLink?.menuTitle}
                    link={`/${locale}/${articlePageLink?.slug}/`}
                    takeActionLink={`/${locale}/${takeActionLink?.slug}/`}
                    takeActionLabel={takeActionLink?.menuTitle}
                    logoText={logoText}
                    locale={locale}
                    items={articlePages.edges}
                    location={location}
                    serviceMenuLinks={serviceMenuLinks.edges}
                    nativeLanguages={nativeLanguages}
                    globalTexts={globalTexts}
                    pageType={pageType}
                />
                {!smallScreen && (
                    <LanguageSelector
                        nativeLanguages={nativeLanguages}
                        globalTexts={globalTexts}
                        isLanguageSelectorActive={isLanguageSelectorActive}
                        setIsLanguageSelectorActive={
                            setIsLanguageSelectorActive
                        }
                        currentSlug={currentSlug}
                        pageType={pageType}
                        setIsBackgroundColor={setIsBackgroundColor}
                    />
                )}
            </>
        );
    }
);

export default FrontpageTemplate;
